// assets
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { IconFileAnalytics } from '@tabler/icons';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const hanhsinhs = {
    id: 'hanh-sinh',
    title: 'Hạnh sinh',
    type: 'group',
    children: [
        {
            id: 'hanh-sinh-bao-cao-tu-tap',
            title: 'Báo cáo tu tập',
            type: 'item',
            url: '/hanh-sinh-bao-cao-tu-tap',
            icon: AutoStoriesIcon,
            breadcrumbs: false
        },
        {
            id: 'hanh-sinh-ket-qua-tu-tap',
            title: 'Kết quả tu tập',
            type: 'item',
            url: '/hanh-sinh-ket-qua-tu-tap',
            icon: IconFileAnalytics,
            breadcrumbs: false
        },
        {
            id: 'hanh-sinh-xuat-ket-qua-tu-tap',
            title: 'Xuất kết quả tu tập',
            type: 'item',
            url: '/hanh-sinh-xuat-ket-qua-tu-tap',
            icon: PlagiarismIcon,
            breadcrumbs: false
        },
        {
            id: 'hanh-sinh-ket-qua-tu-hoc',
            title: 'Tu học nhóm',
            type: 'item',
            url: '/hanh-sinh-ket-qua-tu-hoc',
            icon: Diversity3Icon,
            breadcrumbs: false
        },
        {
            id: 'diem-kiem-tra',
            title: 'Kết quả tu học & tu tập',
            type: 'item',
            url: '/diem-kiem-tra',
            icon: DomainVerificationIcon,
            breadcrumbs: false
        }
    ]
};

export default hanhsinhs;
