// assets
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EditNoteIcon from '@mui/icons-material/EditNote';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const diemdanh = {
    id: 'diemdanh',
    title: 'Điểm Danh Hạnh Sinh',
    type: 'group',
    children: [
        {
            id: 'diem-danh-hanh-sinh',
            title: 'Điểm danh',
            type: 'item',
            url: '/diem-danh-hanh-sinh',
            icon: EditNoteIcon,
            breadcrumbs: false
        },
        {
            id: 'danh-sach-hanh-sinh-diem-danh',
            title: 'Kết quả điểm danh',
            type: 'item',
            url: '/danh-sach-hanh-sinh-diem-danh',
            icon: Diversity3Icon,
            breadcrumbs: false
        }
    ]
};

export default diemdanh;
