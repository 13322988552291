import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import NoneRoutes from './NoneRoutes';
import BQLMainRoutes from './BQLMainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ErrorRoutes from './ErrorRoutes';
import useAuth from 'hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isLoggedIn, user } = useAuth();

    let mainRoutes;

    if (user && user.role === 'NONE') {
        mainRoutes = NoneRoutes(isLoggedIn);
    } else if (user && (user.role === 'BQL' || user.role === 'ADMIN')) {
        mainRoutes = BQLMainRoutes(isLoggedIn);
    } else {
        mainRoutes = MainRoutes(isLoggedIn);
    }

    return useRoutes([mainRoutes, AuthenticationRoutes(isLoggedIn), ErrorRoutes]);
}
