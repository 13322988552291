import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/banquanly')));

// utilities routing
const DanhSachDiemDanhHS = Loadable(lazy(() => import('views/diemdanh/DanhSachDiemDanhHS')));
const DiemdanhHS = Loadable(lazy(() => import('views/diemdanh/DiemDanhHS')));
const DetailDiemDanhHS = Loadable(lazy(() => import('views/diemdanh/DetailDiemDanhHS')));
const KetQuaTuTapHS = Loadable(lazy(() => import('views/banquanly/KetQuaTuTap')));
const KetQuaTuTapHSDetail = Loadable(lazy(() => import('views/banquanly/KetQuaTuTapDetails')));
const KetQuaTuHocNhom = Loadable(lazy(() => import('views/banquanly/KetQuaTuHocNhom')));
const ThongTinHanhSinh = Loadable(lazy(() => import('views/banquanly/ThongTinHanhSinh')));
const ThongTinHanhSinhDetails = Loadable(lazy(() => import('views/banquanly/ThongTinHanhSinhDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const BQLMainRoutes = (isLoggedIn) => {
    return {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: '/',
                children: [
                    {
                        path: 'danh-sach-hanh-sinh-diem-danh',
                        element: <DanhSachDiemDanhHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'diem-danh-hanh-sinh',
                        element: <DiemdanhHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'diem-danh-hanh-sinh-detai',
                        element: <DetailDiemDanhHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'bql-ket-qua-tu-tap',
                        element: <KetQuaTuTapHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'bql-ket-qua-tu-tap/:maHs/:thang',
                        element: <KetQuaTuTapHSDetail />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'bql-ket-qua-tu-hoc',
                        element: <KetQuaTuHocNhom />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'bql-thong-tin-hanh-sinh',
                        element: <ThongTinHanhSinh />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'bql-thong-tin-hanh-sinh/:maHs',
                        element: <ThongTinHanhSinhDetails />
                    }
                ]
            }
        ]
    };
};

export default BQLMainRoutes;
