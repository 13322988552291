import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import baoCaoTuTapReducer from './slices/baoCaoTuTap';
import baoCaoTuHocReducer from './slices/baoCaoTuHoc';
import bqlSystemManageReducer from './slices/bqlSystemManage';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    result: baoCaoTuTapReducer,
    baoCaoTuHoc: baoCaoTuHocReducer,
    systemManage: bqlSystemManageReducer
});

export default reducer;
