import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axiosClient';
import { dispatch } from '../index';

const initialState = {
    error: null,
    fields: [],
    baoCaoTuTaps: [],
    baoCaoTuTap: {},
    baoCaoTuTapDetails: {},
    baoCaoTuTapQuery: {},
    thangAmLichs: [],
    submitResult: {},
    submitResultHocNhom: {},
    baoCaoHocNhoms: {},
    signedUploadUrl: null
};

const slice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getBaoCaoTuTapFieldsSuccess(state, action) {
            state.fields = action.payload;
        },

        createBaoCaoTuTapSuccess(state, action) {
            state.submitResult = action.payload;
        },

        createBaoCaoHocNhomSuccess(state, action) {
            state.submitResultHocNhom = action.payload;
        },

        getBaoCaoHocNhomsSuccess(state, action) {
            state.baoCaoHocNhoms = action.payload;
        },

        getBaoCaoTuTapSuccess(state, action) {
            state.baoCaoTuTaps = action.payload;
        },

        getBaoCaoTuTapDetailsSuccess(state, action) {
            state.baoCaoTuTapDetails = action.payload;
        },

        getBaoCaoTuTapQuerySuccess(state, action) {
            state.baoCaoTuTapQuery = action.payload;
        },

        getSignedUrlSuccess(state, action) {
            state.signedUploadUrl = action.payload;
        },
        getThangAmLichsSuccess(state, action) {
            state.thangAmLichs = action.payload;
        },
        resetSubmitResult: (state) => {
            state.submitResult = initialState.submitResult;
        },
        resetBaoCaoTuTapQuery: (state) => {
            state.baoCaoTuTapQuery = initialState.baoCaoTuTapQuery;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBaoCaoTuTapFields() {
    return async () => {
        try {
            const response = await axios.get('/baocaotutap/fields');

            dispatch(slice.actions.getBaoCaoTuTapFieldsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getThangAmLichs() {
    return async () => {
        try {
            const response = await axios.get('/baocaotutap/thangamlich');

            dispatch(slice.actions.getThangAmLichsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBaoCaoTuTap(input) {
    return async () => {
        try {
            const response = await axios.post('/baocaotutap', { ...input });
            dispatch(slice.actions.createBaoCaoTuTapSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBaoCaoHocNhom(input) {
    return async () => {
        try {
            const response = await axios.post('/baocaotutap/hocnhom', input);
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoHocNhoms() {
    return async () => {
        try {
            const response = await axios.get('/baocaotutap/gethocnhom');
            dispatch(slice.actions.getBaoCaoHocNhomsSuccess(response?.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeBaoCaoHocNhoms(baocaoId) {
    return async () => {
        try {
            const response = await axios.post('/baocaotutap/removehocnhom', baocaoId);
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getBaoCaoTuTaps() {
    return async () => {
        try {
            const response = await axios.get('/baocaotutap');
            dispatch(slice.actions.getBaoCaoTuTapSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuTapDetails(id) {
    return async () => {
        try {
            const response = await axios.get(`/baocaotutap/${id}`);

            dispatch(slice.actions.getBaoCaoTuTapDetailsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuTapQuery(input) {
    return async () => {
        try {
            const response = await axios.post(`baocaotutap/searching`, input);

            dispatch(slice.actions.getBaoCaoTuTapQuerySuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.resetBaoCaoTuTapQuery());
        }
    };
}

export function getSignedUrl(input) {
    return async () => {
        try {
            const response = await axios.post(`baocaotutap/signupload`, { ...input });

            return response;
        } catch (error) {
            console.log('onError-------', error);
        }
    };
}
export function resetSubmitResult() {
    return async () => {
        dispatch(slice.actions.resetSubmitResult());
    };
}
