import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axiosClient';
import { dispatch } from '../index';

const initialState = {
    error: null,
    systemManage: {},
    baoCaoTuTaps: [],
    baoCaoTuHocs: [],
    baoCaoTuTapQuery: {},
    thongTinHanhSinhs: [],
    thongTinHanhSinh: {},
    checkInHanhSinh: {},
    thongTinHanhSinhsCheckin: []
};

const slice = createSlice({
    name: 'systemManage',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getBQLSystemManageSuccess(state, action) {
            state.systemManage = action.payload;
        },
        getBaoCaoTuTapFieldsSuccess(state, action) {
            state.baoCaoTuTaps = action.payload;
        },
        getBaoCaoTuTapDetailsSuccess(state, action) {
            state.baoCaoTuTapQuery = action.payload;
        },
        getBaoCaoTuHocFieldsSuccess(state, action) {
            state.baoCaoTuHocs = action.payload;
        },
        getThongTinHanhSinhsSuccess(state, action) {
            state.thongTinHanhSinhs = action.payload;
        },
        getThongTinHanhSinhSuccess(state, action) {
            state.thongTinHanhSinh = action.payload;
        },
        CheckInHanhSinhSuccess(state, action) {
            state.checkInHanhSinh = action.payload;
        },
        getThongTinHanhSinhCheckinSuccess(state, action) {
            state.thongTinHanhSinhsCheckin = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getKetQuaTuHocExportUrl(thang) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/exportBaoCaoTuTaps/${thang}`);
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getKetQuaDiemDanhExportUrl() {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/exportDiemDanh`);
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBQLSystemManage() {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/reports`);
            dispatch(slice.actions.getBQLSystemManageSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuTaps(thang) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/BaoCaoTuTaps/${thang}`);

            dispatch(slice.actions.getBaoCaoTuTapFieldsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getThongTinHanhSinhs() {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/thongtinhanhsinh`);

            dispatch(slice.actions.getThongTinHanhSinhsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getThongTinHanhSinh(maHs) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/thongtinhanhsinh/${maHs}`);

            dispatch(slice.actions.getThongTinHanhSinhSuccess(response.results[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuTapDetails(maHs, thang) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/BaoCaoTuTaps/${maHs}/${thang}`);

            dispatch(slice.actions.getBaoCaoTuTapDetailsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuHocs(thang) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/BaoCaoTuHocs/${thang}`);

            dispatch(slice.actions.getBaoCaoTuHocFieldsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function diemDanhHanhSinh(maHs) {
    return async () => {
        try {
            if (maHs === 'undefined') return;

            const response = await axios.get(`/BQLSystemManage/checkIn/${maHs}`);

            console.log(response);
            return response;
            //dispatch(slice.actions.CheckInHanhSinhSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDiemDanhHanhSinhs(type) {
    return async () => {
        try {
            const response = await axios.get(`/BQLSystemManage/checkInData/${type}`);

            dispatch(slice.actions.getThongTinHanhSinhCheckinSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeBaoCaoTuHoc(id) {
    return async () => {
        try {
            console.log('id', id);
            return await axios.get(`BQLSystemManage/RemoveBaoCaoTuHoc/${id}`);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
