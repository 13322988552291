import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import Stack from '@mui/material/Stack';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            squareIconTitle,
            secondary,
            shadow,
            sx = {},
            isMobile,
            title,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && !squareIconTitle && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && squareIconTitle && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3"> {title}</Typography>} action={secondary} />
                )}
                {squareIconTitle && title && (
                    <>
                        <CardHeader sx={headerSX} title={title} action={secondary} />
                        <Stack sx={{ mb: 1, ml: 4 }} direction={isMobile ? 'column' : 'row'} spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <SquareIcon sx={{ color: 'black' }}></SquareIcon>
                                <Typography variant="body1"> Màu đen đánh giá hằng ngày</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <SquareIcon sx={{ color: 'rgb(41 0 255)' }}></SquareIcon>
                                <Typography variant="body1"> Màu xanh dương Đánh giá khi có đạo sự phát sinh</Typography>
                            </Stack>

                            <Stack direction="row" spacing={2} alignItems="center">
                                <SquareIcon sx={{ color: '#b30214' }}></SquareIcon>
                                <Typography variant="body1">Màu đỏ mỗi tháng đánh giá một lần vào cuối ngày 23 ÂL hàng tháng</Typography>
                            </Stack>
                        </Stack>
                    </>
                )}
                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
