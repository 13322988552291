// assets
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PeopleIcon from '@mui/icons-material/People';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const banquanly = {
    id: 'banquanly',
    title: 'Ban Quản Lý',
    type: 'group',
    children: [
        {
            id: 'bql-thong-tin-hanh-sinh',
            title: 'Thông tin hạnh sinh',
            type: 'item',
            url: '/bql-thong-tin-hanh-sinh',
            icon: PeopleIcon,
            breadcrumbs: false
        },
        {
            id: 'bql-ket-qua-tu-tap',
            title: 'Kết quả tu tập',
            type: 'item',
            url: '/bql-ket-qua-tu-tap',
            icon: AutoStoriesIcon,
            breadcrumbs: false
        },
        {
            id: 'bql-ket-qua-tu-hoc',
            title: 'Kết quả tu học',
            type: 'item',
            url: '/bql-ket-qua-tu-hoc',
            icon: Diversity3Icon,
            breadcrumbs: false
        }
    ]
};

export default banquanly;
