import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/none')));

// ==============================|| MAIN ROUTING ||============================== //

const NoneRoutes = (isLoggedIn) => {
    return {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            }
        ]
    };
};

export default NoneRoutes;
