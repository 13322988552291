import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const CHECK_INTERVAL = 60 * 20 * 1000;
var CURRENT_VERSION = process.env.REACT_APP_VERSION;

const AppVersionSnackbar = () => {
    const [hasNewVersion, setHasNewVersion] = useState(false);

    const handleReload = () => {
        window.location.reload();
    };

    React.useEffect(() => {
        const getNewVersion = async () => {
            const endpoint = `/version.json?ts=${new Date().getTime()}`;
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                console.warn('Cannot check application version', {
                    endpoint,
                    status: response.status,
                    CURRENT_VERSION
                });
                return;
            }

            return await response.json().then((value) => {
                const latest = value && value.version;
                console.log('version test', {
                    current: CURRENT_VERSION,
                    latest
                });
                if (CURRENT_VERSION && latest && CURRENT_VERSION !== latest) {
                    setHasNewVersion(true);
                    clearInterval(interval);
                }
            });
        };

        const interval = setInterval(() => getNewVersion(), CHECK_INTERVAL);
        getNewVersion();
    }, []);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={hasNewVersion}
            ContentProps={{
                'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">Hệ thống đã cập nhật phiên bản mới.</span>}
            action={[
                <Button key="undo" color="primary" size="small" onClick={handleReload}>
                    Bấm vào đây để cập nhật
                </Button>
            ]}
        />
    );
};

export default AppVersionSnackbar;
