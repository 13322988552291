import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn) => {
    return {
        path: '/',
        element: isLoggedIn ? <Navigate to="/" /> : <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <AuthLogin />
            }
        ]
    };
};

export default AuthenticationRoutes;
