import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axiosClient';
import { dispatch } from '../index';

const initialState = {
    error: null,
    fields: [],
    baoCaoTuHocs: [],
    hanhSinhsByNhom: [],
    diemKiemTraHanhSinh: {},
    baoCaoTuHocDetails: {},
    submitResult: {},
    createBaoCaoTuHoc: {}
};

const slice = createSlice({
    name: 'baoCaoTuHoc',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        getBaoCaoTuHocsSuccess(state, action) {
            state.baoCaoTuHocs = action.payload;
        },
        getDiemKiemTraHanhSinhSuccess(state, action) {
            state.diemKiemTraHanhSinh = action.payload;
        },
        getBaoCaoTuHocDetailsSuccess(state, action) {
            state.baoCaoTuHocDetails = action.payload;
        },
        getHanhSinhsByNhomSuccess(state, action) {
            state.hanhSinhsByNhom = action.payload;
        },
        resetSubmitResult: (state) => {
            state.submitResult = initialState.submitResult;
        },
        createBaoCaoTuHocSuccess(state, action) {
            state.submitResult = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getBaoCaoTuHocs() {
    return async () => {
        try {
            const response = await axios.get('/baocaotuhoc');
            dispatch(slice.actions.getBaoCaoTuHocsSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDiemKiemTraHanhSinh() {
    return async () => {
        try {
            const response = await axios.get('/ketqua-tuhoc-tutap');
            dispatch(slice.actions.getDiemKiemTraHanhSinhSuccess(response.results[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuHocDetails(id) {
    return async () => {
        try {
            const response = await axios.get(`/baocaotuhoc/${id}`);
            dispatch(slice.actions.getBaoCaoTuHocDetailsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBaoCaoTuHocExportUrl(id) {
    return async () => {
        try {
            const response = await axios.post(`/baocaotuhoc/export?baocaoId=${id}`);
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHanhSinhsByNhom() {
    return async () => {
        try {
            const response = await axios.get('/baocaotuhoc/gethanhsinhbynhom');

            dispatch(slice.actions.getHanhSinhsByNhomSuccess(response.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBaoCaoTuHoc(input) {
    return async () => {
        try {
            console.log('input', input);
            const response = await axios.post('/baocaotuhoc', { ...input });
            dispatch(slice.actions.createBaoCaoTuHocSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetSubmitResult() {
    return async () => {
        dispatch(slice.actions.resetSubmitResult());
    };
}
